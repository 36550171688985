.search-container-group {
    display: flex;
    justify-content: space-around;
}

.flex-container1 {
    display: flex;
    flex-direction: row;
    width: 45%;
}

.flex-container2 {
    display: flex;
    flex-direction: row;
    width: 45%;
}

.flex-child {
    flex: 1;
    padding: 16px;
    background: #F8F8F8;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
}

.flex-child:first-child {
    margin-right: 10px;
    padding-top: 20px;
}
