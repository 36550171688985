.Error {
    color: #8B0000;
}

.booking-id-search {
    width: 40%;
}

.user-input-search {
    margin-top: -44px;
    margin-left: 42%;
    width: 58%;
}
.form-field-container {
    display: flow;
}
