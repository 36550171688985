.user-id {
  padding-bottom: 20px;
}
.label {
  font-weight: bold;
}

.row-container {
  display: flex;
  flex-direction: row;
}

.row-ul {
  margin: 0;
  list-style-type: none;
}
.row-li {
  margin: 3px;
}

.row-li-inline {
  margin: 3px;
  display: inline;
}
