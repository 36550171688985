.label {
  font-weight: bold;
}

.input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
  flex-wrap: wrap;
}
.input-details {
  width: 45%;
}

.row-container {
  display: flex;
  flex-direction: row;
}

.id-verification-details {
  padding: 10px;
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(80px, auto);
}

.id-verification-history-details {
  padding-bottom: 20px;
}

.id-verification-details-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: normal;
  width: 100%;
}
