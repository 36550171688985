.detail-page {
    display: flex;
    padding: 40px;
    background-color: #F8F8F8;
}

.detail-page-column {
    flex: 50%;
}

.detail-page-column:first-child {
    padding-right: 16px;
}

.detail-container {
    display: block;
    flex-direction: column;
    padding: 0px;
}

.detail-heading-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}

.detail-heading {
    display: flex;
    align-items: center;
}

.detail-heading h6 {
    margin-bottom: 0;
}

.navigation-bar {
    margin-top: 100px;
    margin-left: 55px;
    margin-right: 70px;
    margin-bottom: -35px;
    display: grid;
    grid-template-columns: 80% 20%;
}
.navigation-button {
    margin-right: 20px;
}

.navigation-left {
    display: flex;
    text-align: left;
}

.navigation-right {
    text-align: right;
}
.detail-close-button,
.detail-close-button:hover {
    border: none !important;
    box-shadow: none;
    padding: 0px;
    margin-left: 12px;
}

.detail-close-image {
    min-width: 22px;
    min-height: 22px;
}

.customer-detail {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 16px;
    background: white;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    margin-bottom: 20px;
}

.container {
    display: flex;
    padding: 55px;
}

.column {
    flex: 100%;
}
