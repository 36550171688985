
.details-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: normal;
  width: 100%;
}

.copy-icon {
  cursor: pointer;
  height: 15px;
  padding-left: 5px;
}
