.toggle {
  padding-right: 20px;
}

.label{
  font-weight: bold;
}

.row-container{
  display: flex;
  flex-direction: row;
}

.personal-details{
  padding: 10px;
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(80px, auto);
}

.personal-details-info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: normal;
  width: 100%;
}

.red-button {
  background-color: red;
  color: white;
  margin-top: 10px;
}

.blue-button {
  margin-top: 10px;
  margin-right: 20px;
}

.blue-button:disabled {
  pointer-events: auto;
}
