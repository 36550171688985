

.user-id {
  padding-bottom: 20px;
}
.label{
  font-weight: bold;
}

.row-container {
  display: flex;
  flex-direction: row;
}

.row-selected {
  --table-cell-background-color: #b3dad1 !important;
  background-color: #b3dad1 !important;
}

.cancel-button {
  width: 100px;
}
