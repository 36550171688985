.label{
  font-weight: bold;
}

.row-container{
  display: flex;
  flex-direction: row;
}
.noWrap {
  white-space: nowrap;
}

.mark-for-changed {
  color: #038364!important;
}

.mark-img-for-changed {
  border: 2px solid #555;
  border-color: #038364;
}

.scrollable-table {
  max-height: calc(100vh - 590px);
}
