.label {
  font-weight: bold;
}

.input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
  flex-wrap: wrap;
}
.input-details {
  width: 45%;
}

.row-container {
  display: flex;
  flex-direction: row;
}

.agreements-details {
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
}
