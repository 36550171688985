.container {
  display: flex;
  padding: 55px;
}

.column {
  flex: 100%;
}
.column:first-child {
  padding-right: 18px;
}

.login-container {
  padding-top: 200px;
  padding-bottom: 52px;
  width: 100%;
  display: grid;
  grid-template-columns: 35% 15% 15% 35% ;
  gap: 10px;
  text-align: center;

}

.row1col2 {
  grid-column: 2;
  grid-row: 1;
}

.row1col4 {
  grid-column: 3;
  grid-row: 1;
}

.row2col2 {
  grid-column: 2;
  grid-row: 2;
}

.row2col4 {
  grid-column: 3;
  grid-row: 2;
}

.login-div {
  text-align: center;
}

.login-logo{
  cursor: pointer;
}

.heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 24px;
}

.display-inline {
  display: inline;
  color: #004666;
  font-size: 30px;
}

.banner {
  padding: 0px;
}
.banner-heading {
  width: 800px;
  padding-top: 24px;
}

.login-logo {
  height: 50px;
}
