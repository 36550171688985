.filter-group{
    padding: 10px;
    background: white;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: minmax(80px, auto);
    height: 100px;
    width: 350px;
}
